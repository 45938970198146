var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-toolbar-items',{staticClass:"top-navbar-icons__wrapper"},[(_vm.isUploadFullAccess)?_c('div',{staticClass:"right-nav-item upload",attrs:{"data-pendo-id":"upload-prez-icon"}},[_c('v-tooltip',{attrs:{"bottom":"","max-width":"250","content-class":"tooltip-content","fixed":"","open-delay":"500","close-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({class:("ma-0 rounded-circle " + (_vm.isTrialExpired ? 'disabled' : '')),attrs:{"depressed":"","small":"","icon":"","ripple":false,"disabled":_vm.isTrialExpired},on:{"click":_vm.onuploadIconClick}},'v-btn',attrs,false),on),[_c('v-img',{attrs:{"contain":"","alt":"","src":("/assets/img/upload-cta-nav" + (_vm.isTrialExpired ? '-disabled' : '') + ".svg")}})],1)]}}],null,false,958032760)},[_c('span',[_vm._v("Upload")])])],1):_vm._e(),_c('div',{staticClass:"right-nav-item share-fingerprint-icon",attrs:{"data-pendo-id":"share-fingerprint-icon"}},[[_c('v-tooltip',{attrs:{"bottom":"","max-width":"250","content-class":"tooltip-content","fixed":"","open-delay":"500","close-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('NewFeatureBadge',{attrs:{"module-name":"sharefingerprintsicon","feature-id":'sharefingerprintsicon_id',"offsetX":'27',"offsetY":'40',"size":'small'}},[_c('v-btn',_vm._g(_vm._b({class:("ma-0 rounded-circle " + (_vm.isTrialExpired ? 'disabled' : '')),attrs:{"depressed":"","small":"","icon":"","ripple":false,"disabled":_vm.isTrialExpired},on:{"click":_vm.openShareFingerprint}},'v-btn',attrs,false),on),[(_vm.isTrialExpired)?_c('v-img',{attrs:{"contain":"","alt":"","src":'/assets/img/fingerprint-nav-icon-grey.svg'}}):(_vm.showShareFingerprint)?_c('v-img',{attrs:{"contain":"","alt":"","src":'/assets/img/fingerprint-nav-icon-selected.svg'}}):_c('v-img',{attrs:{"contain":"","alt":"","src":'/assets/img/fingerprint-nav-icon-black.svg'}})],1)],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('navbarActions.shareFingerprint')))])]),_c('v-menu',{attrs:{"absolute":"","offset-y":"","position-x":_vm.menuPosition.x,"position-y":_vm.menuPosition.y,"nudge-width":700,"close-on-click":_vm.closeOnClick,"close-on-content-click":false},model:{value:(_vm.showShareFingerprint),callback:function ($$v) {_vm.showShareFingerprint=$$v},expression:"showShareFingerprint"}},[(_vm.showShareFingerprint)?_c('div',[_c('share-finger-print',{attrs:{"type":"menu","origin":_vm.shareFingerPrintOrigin},on:{"close":_vm.handleShareFingerprintClose,"subModalOpen":_vm.changeCloseOnClick}})],1):_vm._e()])]],2),_c('div',{staticClass:"right-nav-item add-audience-icon",attrs:{"data-pendo-id":"add-audience-icon"}},[[_c('v-tooltip',{attrs:{"bottom":"","max-width":"250","content-class":"tooltip-content","fixed":"","open-delay":"500","close-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({class:("ma-0 rounded-circle " + (_vm.isTrialExpired ? 'disabled' : '')),attrs:{"depressed":"","small":"","icon":"","ripple":false,"disabled":_vm.isTrialExpired},on:{"click":_vm.openAddAudienceMenu}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"name":"add-audience-icon","color":_vm.colorAddAudience}},[_vm._v(" "+_vm._s(_vm.audienceIcon)+" ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('fingerprint.searchPage.addNewAudience')))])]),_c('v-menu',{attrs:{"absolute":"","offset-y":"","position-x":_vm.menuPosition.x,"position-y":_vm.menuPosition.y,"nudge-width":578,"close-on-click":_vm.closeOnClick,"close-on-content-click":false},model:{value:(_vm.showAddAudience),callback:function ($$v) {_vm.showAddAudience=$$v},expression:"showAddAudience"}},[(_vm.showAddAudience)?_c('div',[_c('add-collegue-detail',{attrs:{"close":_vm.closeAddColleague,"trigger":"header","origin":"header","id":"add-audience-menu"},on:{"subModalOpen":_vm.changeCloseOnClick}})],1):_vm._e()])]],2),_c('div',{staticClass:"right-nav-item notification-icon",attrs:{"data-pendo-id":"notification-icon"}},[[_c('v-badge',{staticClass:"notification-badge",attrs:{"content":_vm.getNotificationNotSeen || 0,"top":"","offset-x":"13","offset-y":"13","dot":"","bordered":"","value":_vm.getNotificationNotSeen || 0}},[_c('v-tooltip',{attrs:{"bottom":"","max-width":"250","content-class":"tooltip-content","fixed":"","open-delay":"500","close-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({class:("ma-0 rounded-circle " + (_vm.isTrialExpired ? 'disabled' : '')),attrs:{"depressed":"","small":"","icon":"","ripple":false,"disabled":_vm.isTrialExpired},on:{"click":_vm.openNotificationsMenu}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"name":"notification-icon","color":_vm.colorNotifications}},[_vm._v(" "+_vm._s(!_vm.isTrialExpired && _vm.notifications && _vm.notifications.length > 0 && _vm.notifications.filter(function (notif) { return notif.seen === false; }) .length > 0 ? _vm.newNotificationIcon : _vm.generalNotificationIcon)+" ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(("" + (_vm.notifications && _vm.notifications.length > 0 && _vm.notifications.filter(function (notif) { return notif.seen === false; }).length > 0 ? ("" + (_vm.notifications.filter(function (notif) { return notif.seen === false; }) .length) + (_vm.$t('navbarActions.newNotifications'))) : _vm.$t('navbarActions.notifications')))))])])],1),_c('v-menu',{attrs:{"absolute":"","attach":true,"offset-y":"","position-x":_vm.menuPosition.x - 425,"position-y":_vm.menuPosition.y,"nudge-width":575,"max-height":_vm.notifications && _vm.notifications.length <= 0
            ? 160
            : _vm.notificationsMenuHeight,"close-on-content-click":false,"content-class":"notificationListMenu"},model:{value:(_vm.showNotificationMenu),callback:function ($$v) {_vm.showNotificationMenu=$$v},expression:"showNotificationMenu"}},[(_vm.loadingNotificationsFlag)?_c('div',{staticClass:"spinnerContainer"},[_c('ClipLoader',{attrs:{"color":"#21a7e0"}}),_c('h2',[_vm._v(_vm._s(_vm.$t('navbarActions.loadingNotifications')))])],1):_c('div',[(_vm.notifications && _vm.notifications.length > 0)?_c('div',[_c('v-icon',{staticClass:"notificationMenuCloseIcon",attrs:{"medium":""},on:{"click":_vm.closeNotificationsMenu}},[_vm._v(" mdi-close ")]),_c('div',{staticClass:"notificationsHeading"},[_c('div',{staticClass:"notification__title"},[_vm._v(" "+_vm._s(_vm.$t('navbarActions.notifications'))+" ")])]),_c('v-list',{ref:"notificationListRef",style:({
                height: ((_vm.notificationsMenuHeight -
                  (_vm.notifications && _vm.notifications.length > 5 ? 116 : 114)) + "px"),
                overflow: 'auto',
                padding: '0',
              }),attrs:{"id":"notifications-menu"}},_vm._l((_vm.notifications),function(item,index){return _c('v-list-item',{key:index,class:index === _vm.notifications.length - 1
                    ? 'last-notification-item'
                    : 'notification-item',staticStyle:{"marign-bottom":"0px !important"}},[_c('div',{staticClass:"titleAndContainer"},[_c('div',{staticClass:"top-panel"},[_c('div',{class:item.read === false
                          ? 'notification-title'
                          : 'notification-title-when-read'},[_vm._v(" "+_vm._s(item.notification)+" ")])]),_c('div',{staticClass:"bottom-panel"},[_vm._v(" "+_vm._s(_vm.elapsedTime(item.created_at))+" ")])]),_c('div',{staticClass:"readUnreadToggleContainer"},[_c('v-btn',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                      content: ("" + (item.read === true
                          ? _vm.$t('navbarActions.markAsUnread')
                          : _vm.$t('navbarActions.markAsRead'))),
                      placement: ("" + (_vm.notifications.length === 1 ? 'left' : 'bottom')),
                    }),expression:"{\n                      content: `${\n                        item.read === true\n                          ? $t('navbarActions.markAsUnread')\n                          : $t('navbarActions.markAsRead')\n                      }`,\n                      placement: `${\n                        notifications.length === 1 ? 'left' : 'bottom'\n                      }`,\n                    }"}],class:item.read && item.read === true
                        ? 'markUnreadButton'
                        : 'markReadButton',attrs:{"text":"","icon":"","color":item.read && item.read === true ? '#b6b6b6' : '#21a7e0',"id":item.id},on:{"click":function($event){return _vm.handleReadUnreadToggleClick({
                        notifId: item.id,
                        readStatus: item.read,
                        notification: item.notification,
                      })}}},[_c('v-icon',{attrs:{"large":""}},[_vm._v(" mdi-circle-medium ")])],1)],1)])}),1)],1):_c('div',{staticClass:"no-notification"},[_c('img',{attrs:{"src":"/assets/img/no-notifications.svg","alt":""}}),_c('div',{staticClass:"no-notification-title"},[_vm._v(" "+_vm._s(_vm.$t('navbarActions.thereAreNoNotifications'))+" ")])])])])]],2),_c('div',{staticClass:"right-nav-item share-free-trial-icon",attrs:{"data-pendo-id":"share-free-trial-icon"}},[[_c('v-tooltip',{attrs:{"bottom":"","max-width":"250","content-class":"tooltip-content","fixed":"","open-delay":"500","close-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({class:("ma-0 rounded-circle " + (_vm.isTrialExpired ? 'disabled' : '')),attrs:{"depressed":"","small":"","icon":"","ripple":false,"disabled":_vm.isTrialExpired},on:{"click":_vm.openShareMenu}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"name":"top-bar-share-icon","color":_vm.colorShare}},[_vm._v(" "+_vm._s(_vm.shareIcon)+" ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('common.share')))])]),_c('v-menu',{attrs:{"absolute":"","offset-y":"","position-x":_vm.menuPosition.x,"position-y":_vm.menuPosition.y,"nudge-width":900,"close-on-content-click":false,"close-on-click":_vm.closeOnClick,"id":"share-free-trail-menu"},model:{value:(_vm.showShare),callback:function ($$v) {_vm.showShare=$$v},expression:"showShare"}},[(_vm.showShare)?_c('ShareFreeTrail',{attrs:{"close":_vm.closeShareMenu,"origin":"top nav bar"},on:{"subModalOpen":_vm.changeCloseOnClick}}):_vm._e()],1)]],2),_c('SwitchLanguage'),_c('div',{staticClass:"right-nav-item profile-link",attrs:{"data-pendo-id":"profile-link"}},[_c('v-tooltip',{attrs:{"bottom":"","max-width":"250","content-class":"tooltip-content","fixed":"","open-delay":"500","close-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"profile-image-wrapper",attrs:{"name":"profile-icon","data-pendo-id":"nav-profile-avatar"},on:{"click":_vm.handleClickProfile}},'div',attrs,false),on),[(_vm.profileImage !== '/assets/img/left-nav/profile.svg')?_c('div',{staticClass:"profile-image-upload"},[_c('v-img',{attrs:{"contain":"","src":_vm.profileImage,"alt":""}})],1):_c('div',[_c('v-avatar',{staticClass:"profile-user-avatar",attrs:{"color":"rgb(88, 168, 222)","size":"24","rounded":""}},[_c('span',{staticClass:"white--text text-body-2",staticStyle:{"color":"white","font-weight":"250"}},[_vm._v(_vm._s(_vm.userInitials))])])],1)])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('navbarActions.profile')))])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }